<article class="page" id="projectRequestView" *ngIf="projectRequestObservable | async as projectRequest">
    <header>
        <h1>Richiesta</h1>
    </header>
    <div class="content">
        <mat-card class="infoCard">

            <mat-card-header>
                <mat-icon mat-card-avatar>chat_bubble</mat-icon>

                <mat-card-title>{{ projectRequest.title }} <span class="tag warning" *ngIf="projectRequest.isClosed()">Chiusa</span></mat-card-title>
                <mat-card-subtitle>{{ projectRequest.requester }} - {{ projectRequest.date.toDate() | date }}</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
                <p>Descrizione:</p>
                <div style="overflow-wrap: break-word; word-wrap: break-word; width: auto">{{ projectRequest.description }}</div>
            </mat-card-content>

        </mat-card>

        <div *ngIf="isLoggedId()">
            <mat-card class="chat" *ngIf="chatObservable | async as chat">
                <mat-card-header>
                    <mat-card-title>Chat</mat-card-title>
                </mat-card-header>

                <mat-list>
                    <mat-list-item class="message" *ngFor="let message of chat.messages"
                                   [class.self]="isOwnMessage(message)" [class.other]="!isOwnMessage(message)">
                        <div matListItemTitle>{{ message.senderName }}</div>
                        <div matListItemLine>{{ message.content }}</div>
                    </mat-list-item>

                    <mat-list-item class="message noMessage" *ngIf="chat.messages.length == 0">
                        <div matListItemTitle>Nessun messaggio</div>
                    </mat-list-item>

                </mat-list>

                <mat-card-footer>
                    <mat-card-actions>

                        <mat-form-field appearance="fill">
                            <textarea #messageInput name="notes" type="text" matInput
                                      placeholder="Invia messaggio..."></textarea>
                        </mat-form-field>
                        <button mat-raised-button i18n="@@send"
                                (click)="sendMessage(chat, messageInput)" [disabled]="! messageInput.value">Invia
                        </button>
                    </mat-card-actions>
                </mat-card-footer>

                <button *ngIf="!chat.quoteId" style="margin-top: 2rem; margin-bottom: 2rem" mat-raised-button color="primary" i18n="@@prepare_quote"
                        (click)="prepareQuote(chat, projectRequest)">Crea preventivo
                </button>
                <button *ngIf="chat.quoteId" style="margin-top: 2rem; margin-bottom: 2rem" mat-raised-button color="primary" i18n="@@check_quote"
                        [routerLink]="['/invoicing/quotes/', chat.quoteId]">Vedi preventivo
                </button>

            </mat-card>
        </div>
    </div>
</article>
